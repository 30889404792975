import React from 'react';
import Logo from './Logo';

function Loader({ show = true, text = undefined, top = false }) {
    if (!show) {
        return null;
    }
    return (
        <div
            className="fixed z-50 top-0 left-0 bottom-0 right-0 backdrop-filter backdrop-blur-sm bg-white bg-opacity-30 flex flex-col items-center justify-center text-center"
            style={top ? { zIndex: 60 } : {}}
        >
            <div>
                <div className="pb-3 animate-spin">
                    <Logo />
                </div>
            </div>
            {text && <div className="text-2xl pt-4">{text}</div>}
        </div>
    );
}

export default Loader;