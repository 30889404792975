import { handleActions, createAction } from 'redux-actions';

const REQUEST = 'folders/REQUEST';
const SUCCESS = 'folders/SUCCESS';
const ERROR = 'folders/ERROR';
const REQUEST_LIST = 'folders/REQUEST_LIST';
const LIST = 'folders/LIST';
const REMOVE = 'folders/REMOVE';
const REMOVE_FOLDER = 'folders/REMOVE_FOLDER';
const CREATE = 'folders/CREATE';
const CREATED = 'folders/CREATED';
const UPDATE = 'folders/UPDATE';
const SET_CURRENT = 'folders/SET_CURRENT';

export const initialState = {
    list: [],
    current: null,
    lastCreatedFolderId: null,
    loading: false,
    error: null,
};

export default handleActions({
    [REQUEST]: state => ({ ...state, loading: true }),
    [REQUEST_LIST]: state => ({ ...state, loading: true }),
    [CREATE]: state => ({ ...state, loading: true }),
    [CREATED]: (state, { payload }) => ({ ...state, lastCreatedFolderId: payload, loading: false }),
    [UPDATE]: state => ({ ...state, loading: true }),
    [REMOVE]: state => ({ ...state, loading: true }),
    [REMOVE_FOLDER]: (state, { payload }) => ({ ...state, loading: false, list: state.list.filter(f => f.id !== payload) }),
    [SUCCESS]: (state) => ({ ...state, loading: false }),
    [ERROR]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
    [LIST]: (state, { payload }) => ({ ...state, loading: false, error: null, list: payload }),
    [SET_CURRENT]: (state, { payload }) => ({ ...state, current: payload }),
}, initialState);


export const request = createAction(REQUEST, id => id);
export const requestList = createAction(REQUEST_LIST, f => f);
export const list = createAction(LIST, list => list);
export const error = createAction(ERROR, e => e);
export const success = createAction(SUCCESS, any => any);
export const create = createAction(CREATE, f => f);
export const created = createAction(CREATED, id => id);
export const update = createAction(UPDATE, (id, folder) => ({ id, folder }));
export const remove = createAction(REMOVE, id => id);
export const removeFolder = createAction(REMOVE_FOLDER, id => id);
export const setCurrent = createAction(SET_CURRENT, c => c);

export const foldersList = state => state.folders.list;
export const currentFolderId = state => state.folders.current?.id;
