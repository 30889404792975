import React from 'react';
import { Warning } from './Icons';

function Modal({ type = 'info', onClickOk, onClickCancel = null, ...props }) {
    return (
        <div className="fixed top-0 left-0 bottom-0 right-0 z-20">
            <div className="bg-white opacity-90 absolute z-0 left-0 right-0 bottom-0 top-0"/>
            <div className="p-4 flex justify-center items-center h-full relative z-10">
                <div className="flex flex-col items-center">
                    <div><Warning size={35} fill="#016cae"/></div>
                    <div className="text-2xl font-bold py-5">{props.children}</div>
                    <div>
                        {onClickCancel && <button onClick={onClickCancel}>Cancel</button>}
                        <button className="bg-baltic px-6 py-1 rounded shadow text-white font-bold" onClick={onClickOk}>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;