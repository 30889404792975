import { combineReducers, Reducer } from 'redux';
import users, { initialState as usersState } from './users';
import auth, { initialState as authState } from './auth';
import application, { initialState as applicationState } from './application';
import files, { initialState as filesState } from './files';
import folders, { initialState as foldersState } from './folders';

export const initialState = {
    users: usersState,
    auth: authState,
    application: applicationState,
    files: filesState,
    folders: foldersState,
};

export default (router: any = null): Reducer => combineReducers({
    router,
    users,
    auth,
    application,
    files,
    folders,
});