import Logo from './Logo';
import React from 'react';

function FullLogo() {
    return (
        <div className="flex items-center relative">
            <div
                className="font-bold text-xl relative z-10 text-shadow"
                style={{ marginRight: -8, marginTop: -3, textShadow: '0 2px 4px rgba(0,0,0,0.10)' }}>
                disc
            </div>
            <Logo size={32}/>
        </div>
    )
}

export default FullLogo;