import React, { ReactElement, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileIcon } from './Icons';

function UploadWrapper({ onFilesUpload, children }): ReactElement {
    const onDrop = useCallback(acceptedFiles => {
        onFilesUpload(acceptedFiles);
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true });
    return (
        <div {...getRootProps()} className={`relative ${isDragActive ? 'opacity-60' : ''} duration-300 h-full`}>
            {children}
            <div className={`absolute left-2 top-2 right-2 bottom-2 ${isDragActive ? 'block' : 'hidden'} border-2  border-blue-100 border-dashed py-6 rounded text-center `}>
                <input {...getInputProps()} />
                <div className="flex justify-center">
                    <FileIcon fill='#fff' size={50}/>
                </div>
                <div className="pt-6 font-bold">
                    {isDragActive ? 'Drop the files here ...' : 'Drag \'n\' drop some files here, or click to select files'}
                </div>
            </div>
        </div>
    );
}

export default UploadWrapper;