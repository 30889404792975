import { call, ForkEffect, put, select, takeEvery } from '@redux-saga/core/effects';
import * as foldersActions from '../ducks/folders';
import { apiRequest } from './index';
import { getApiErrorMessage } from '../functions';
import * as applicationActions from '../ducks/application';

function* requestListProcess({ payload }): IterableIterator<any> {
    const currentFolderId = yield select(foldersActions.currentFolderId);
    if (payload !== currentFolderId) {
        yield put(foldersActions.setCurrent(payload))
    }
    try {
        const folders = yield call(apiRequest, '/api/folders' + (payload ? `/${payload}/folders` : ''));
        yield put(foldersActions.list(folders));
    } catch (e) {
        yield put(foldersActions.error(getApiErrorMessage(e)));
        yield put(applicationActions.error(getApiErrorMessage(e)));
    }
}

function* createProcess({ payload }): IterableIterator<any> {
    try {
        const currentFolderId: any = yield select(foldersActions.currentFolderId);
        const folder: any = yield call(apiRequest, '/api/folders' + (currentFolderId ? `/${currentFolderId}` : ''), 'POST', payload);
        const folders: [] = yield select(foldersActions.foldersList);
        yield put(foldersActions.list([...folders, folder]));
        yield put(foldersActions.created(folder.id));
    } catch (e) {
        yield put(foldersActions.error(getApiErrorMessage(e)));
        yield put(applicationActions.error(getApiErrorMessage(e)));
    }
}

function* updateProcess({ payload: { id, folder } }): IterableIterator<any> {
    try {
        yield put(foldersActions.created(null));
        const updatedFolder: { id: string, folderId: string } = yield call(apiRequest, '/api/folders/' + id, 'PUT', folder);
        const folders: any = yield select(foldersActions.foldersList);
        const currentFolderId = (yield select(foldersActions.currentFolderId)) || '';

        yield put(foldersActions.list(
            updatedFolder.folderId != currentFolderId
                ? folders.filter(f => f.id !== updatedFolder.id)
                : folders.map(f => f.id === id ? updatedFolder : f)
        ));
    } catch (e) {
        yield put(foldersActions.error(getApiErrorMessage(e)));
        yield put(applicationActions.error(getApiErrorMessage(e)));
    }
}

function* removeProcess({ payload }): IterableIterator<any> {
    try {
        yield call(apiRequest, '/api/folders/' + payload, 'DELETE');
        yield put(foldersActions.removeFolder(payload));
    } catch (e) {
        yield put(foldersActions.error(getApiErrorMessage(e)));
        yield put(applicationActions.error(getApiErrorMessage(e)));
    }
}

function* requestProcess({ payload: id }): IterableIterator<any> {
    yield put(foldersActions.setCurrent(id ? { id } : null));
    if (!id) {
        return
    }
    try {
        const folder = yield call(apiRequest, '/api/folders/' + id);
        yield put(foldersActions.setCurrent(folder));
    } catch (e) {
        yield put(foldersActions.error(getApiErrorMessage(e)));
        yield put(applicationActions.error(getApiErrorMessage(e)));
    }
}

export function* folders(): IterableIterator<ForkEffect> {
    yield takeEvery(foldersActions.requestList, requestListProcess);
    yield takeEvery(foldersActions.create, createProcess);
    yield takeEvery(foldersActions.update, updateProcess);
    yield takeEvery(foldersActions.remove, removeProcess);
    yield takeEvery(foldersActions.request, requestProcess);
}