import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import File from './File';

function Files({ files, folders, onOpenFile, onOpenFolder, onRenameFile, onRenameFolder, onSelect, selected, onMoveToFolder }) {
    return (
        <DndProvider backend={HTML5Backend}>
            <div className="flex flex-wrap items-start gap-4 py-6 px-4 relative z-10 min-h-full">
                <div className="absolute left-0 top-0 right-0 bottom-0 z-0" onClick={() => onSelect(null)}/>
                {files.length === 0 && folders.length ===0 && <div className="text-sm text-mint">Тут пусто</div>}
                {folders.map(f => <div key={`folder-${f.id}`} className="mb-6 relative z-20">
                    <File
                        file={f}
                        onDoubleClick={() => onOpenFolder(f.id)}
                        selected={selected === f.id}
                        folder
                        onRename={name => onRenameFolder(f.id, name)}
                        onClick={() => onSelect(f.id)}
                        onMoveToFolder={onMoveToFolder}
                    />
                </div>)}
                {files.map(f => <div key={`file-${f.id}`} className="mb-6 relative z-20">
                    <File
                        file={f}
                        onDoubleClick={() => onOpenFile(f.id)}
                        onRename={name => onRenameFile(f.id, name)}
                        onClick={() => onSelect(f.id)}
                        selected={selected === f.id}
                        onMoveToFolder={onMoveToFolder}
                    />
                </div>)}
            </div>
        </DndProvider>
    );
}

export default Files;