import ProtectedRoute from 'react-route-protected';
import React, { ReactElement, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';

import { RootState } from '../Application';
import { getTokens } from '../tokens';
import { requestMe } from '../ducks/users';
import { checkToken, setFrom } from '../ducks/auth';
import { removeMessage, setLoading } from '../ducks/application';
import Modal from '../components/Modal';
import Loader from '../components/Loader';
import NotFoundPage from '../components/NotFoundPage';
import FileManager from './FileManager';
import { ApplicationMessageType } from '../dataTypes/applicationMessage';
import Auth from './Auth';


// @ts-ignore
function Root({ history, me, requestMe, setFrom, loading, setLoading, hideModal, message, checkToken }): ReactElement {
    useEffect(() => {
        setFrom(history.location.state?.from || '/disco');
        const [t, rt] = getTokens()
        if (!me) {
            (t && rt) ? requestMe() : setLoading(false);
        }
        checkToken();
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Switch>
                <Route exact path="/auth" component={Auth}/>
                <ProtectedRoute exact path="/disco" component={FileManager} isAuthorized={!!me} authPath="/auth"/>
                <ProtectedRoute exact path="/disco/:folder" component={FileManager} isAuthorized={!!me} authPath="/auth"/>
                <Route path="*" component={NotFoundPage} />
            </Switch>
            {message && <Modal type={message.type || ApplicationMessageType.info} onClickOk={hideModal}>{message.text}</Modal>}
        </>
    );
}

export default connect(
    (state: RootState) => ({
        me: state.users.me,
        loading: state.application.loading,
        message: state.application.message,
    }),
    dispatch => ({
        requestMe: () => dispatch(requestMe()),
        setFrom: from => dispatch(setFrom(from)),
        setLoading: l => dispatch(setLoading(l)),
        hideModal: () => dispatch(removeMessage()),
        checkToken: () => dispatch(checkToken())
    })
)(Root);