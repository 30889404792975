import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

function NotFoundPage(): ReactElement {
    return (
        <div className="container mx-auto p-4">
            <h1>Страница не найдена</h1>
            <p>
                Перейдите <Link to="/disco">на главую</Link>
            </p>
            <div className="text-2xl">
                404
            </div>
        </div>
    );
}

export default NotFoundPage;