import { call, ForkEffect, put, select, takeEvery } from '@redux-saga/core/effects';

import * as filesActions from '../ducks/files';
import * as applicationActions from '../ducks/application';
import { apiRequest } from './index';
import { getApiErrorMessage, SERVER_REQUESTS_BASE } from '../functions';
import * as foldersActions from '../ducks/folders';

export function* uploadProcess({ payload }): IterableIterator<any> {
    const uploadedFiles = [];
    for (const file of payload) {
        const formData = new FormData();
        formData.append('file', file);
        yield put(filesActions.uploading(file));
        try {
            const currentFolderId: any = yield select(foldersActions.currentFolderId);
            const uploadedFile: { id: string, folderId: string } = yield call(
                apiRequest,
                `/api/${currentFolderId ? `folders/${currentFolderId}/` : ''}files`,
                'post',
                formData,
                { 'Content-Type': 'multipart/form-data' }
            );
            yield put(filesActions.success(uploadedFile.id));
            yield put(filesActions.file(uploadedFile));
            yield put(filesActions.uploading(null));
        } catch (e) {
            yield put(filesActions.error(getApiErrorMessage(e)));
            yield put(applicationActions.error(getApiErrorMessage(e)));
        }
        yield put(filesActions.uploading(null));
    }
    return uploadedFiles;
}

function* requestProcess({ payload }): IterableIterator<any> {
    if (payload !== (yield select(foldersActions.currentFolderId))){
        yield put(foldersActions.setCurrent(payload))
    }
    try {
        const file = yield call(apiRequest, '/api/files/' + payload);
        yield put(filesActions.file(file));
    } catch (e) {
        yield put(applicationActions.error(getApiErrorMessage(e)));
    }
}

function* removeProcess({ payload }): IterableIterator<any> {
    try {
        yield call(apiRequest, '/api/files/' + payload, 'DELETE');
        yield put(filesActions.success());
        yield put(filesActions.lastRemovedFile(payload));
        yield put(filesActions.removeFile({ id: payload }));
    } catch (e) {
        yield put(filesActions.error(getApiErrorMessage(e)));
        yield put(applicationActions.error(getApiErrorMessage(e)));
    }
}

function* requestListProcess({ payload }): IterableIterator<any> {
    try {
        const files: any = yield call(apiRequest, `/api/${payload ? `folders/${payload}/` : ''}files`);
        yield put(filesActions.list(files));
    } catch (e) {
        yield put(filesActions.error(getApiErrorMessage(e)));
        yield put(applicationActions.error(getApiErrorMessage(e)));
    }
}

function* openProcess({ payload }): IterableIterator<any> {
    // const file = yield select(filesActions.fileById, payload)
    window.open(SERVER_REQUESTS_BASE + '/api/files/' + payload + '/download','_blank');
}

function* updateProcess({ payload: { id, file } }): IterableIterator<any> {
    try {
        const updatedFile: { id: string, folderId: string } = yield call(apiRequest, '/api/files/' + id, 'PUT', file);
        const files: any = yield select(filesActions.filesList);
        const currentFolderId = yield select(foldersActions.currentFolderId);
        yield put(filesActions.list(
            currentFolderId !== updatedFile.folderId
                ? files.filter(f => f.id !== updatedFile.id)
                : files.map(f => f.id === id ? updatedFile : f)
        ));
    } catch (e) {
        yield put(filesActions.error(getApiErrorMessage(e)));
        yield put(applicationActions.error(getApiErrorMessage(e)));
    }
}


export function* files(): IterableIterator<ForkEffect> {
    yield takeEvery(filesActions.upload, uploadProcess);
    yield takeEvery(filesActions.request, requestProcess);
    yield takeEvery(filesActions.remove, removeProcess);
    yield takeEvery(filesActions.requestList, requestListProcess);
    yield takeEvery(filesActions.open, openProcess);
    yield takeEvery(filesActions.update, updateProcess)
}