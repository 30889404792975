import React from 'react';

function ControlBarButton({ onClick = () => {}, ...props }) {
    return (
        <button
            className="p-2 hover:bg-black hover:bg-opacity-10 duration-200"
            onClick={onClick}
        >
            {props.children}
        </button>
    );
}

export default ControlBarButton;