import React from 'react';
import { ArrowSmLeftIcon, FolderAddIcon, TrashIcon } from '@heroicons/react/solid';
import ControlBarButton from './ControlBarButton';
import FullLogo from './FullLogo';
import { MoveToTopIcon } from './Icons';

function ControlBar({ onClickBack, onCreateFolder, selected, onClickDelete, current, onMoveToTop }) {
    return (
        <>
            <div className="flex gap-2 bg-mint text-white items-center px-4">
                <div className="border-r border-white pr-4">
                    <FullLogo />
                </div>
                <div className="flex">
                    <ControlBarButton onClick={onClickBack} disbled={!current}>
                        <ArrowSmLeftIcon className="h-8"/>
                    </ControlBarButton>
                    <ControlBarButton onClick={onCreateFolder}>
                        <FolderAddIcon className="h-8 stroke-current" fill="transparent"/>
                    </ControlBarButton>
                    {selected && current && (
                        <ControlBarButton onClick={onMoveToTop}>
                            <div style={{ marginTop: -6 }}>
                                <MoveToTopIcon fill="#fff" size={28} />
                            </div>
                        </ControlBarButton>
                    )}
                    {selected && (
                        <ControlBarButton onClick={onClickDelete}>
                            <TrashIcon className="h-6 stroke-current"/>
                        </ControlBarButton>
                    )}
                </div>
                <div/>
            </div>
        </>
    );
}

export default ControlBar;