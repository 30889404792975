import { handleActions, createAction } from 'redux-actions';
import { stat } from 'fs';

const UPLOAD = 'files/UPLOAD';
const UPLOADING = 'files/UPLOADING';
const SUCCESS = 'files/SUCCESS';
const ERROR = 'files/ERROR';
const REQUEST = 'files/REQUEST';
const REQUEST_LIST = 'files/REQUEST_LIST';
const LIST = 'files/LIST';
const FILE = 'files/FILE';
const LAST_UPLOADED_FILE = 'files/LAST_UPLOADED_FILE';
const LAST_REMOVED_FILE = 'files/LAST_REMOVED_FILE';
const REMOVE = 'files/REMOVE';
const REMOVE_FILE = 'files/REMOVE_FILE';
const OPEN = 'files/OPEN';
const UPDATE = 'UPDATE/UPDATE';

export const initialState = {
    files: [],
    lastUploadedFile: null,
    uploadingFile: null,
    loading: false,
    error: null,
};

export default handleActions({
    [UPLOAD]: state => ({ ...state, loading: true }),
    [UPLOADING]: (state, { payload }) => ({ ...state, uploadingFile: payload }),
    [REQUEST]: state => ({ ...state, loading: true }),
    [REQUEST_LIST]: state => ({ ...state, loading: true }),
    [REMOVE]: state => ({ ...state, loading: true }),
    [SUCCESS]: (state, { payload }) => ({ ...state, loading: false, lastUploadedFile: payload }),
    [ERROR]: (state, { payload }) => ({ ...state, loading: false, error: payload, lastUploadedFile: null, }),
    [LAST_UPLOADED_FILE]: (state, { payload }) => ({ ...state, lastUploadedFile: payload }),
    [LAST_REMOVED_FILE]: (state, { payload }) => ({ ...state, lastRemovedFile: payload }),
    [FILE]: (state, { payload }) => ({ ...state, loading: false, error: null, files: [ ...state.files, payload ] }),
    [LIST]: (state, { payload }) => ({ ...state, loading: false, error: null, files: payload }),
    [REMOVE_FILE]: (state, { payload }) => {
        return ({  ...state, loading: false, error: null, files: state.files.filter(f => f.id !== payload.id), lastRemovedFile: payload.id })
    },
    [UPDATE]: state => ({ ...state, loading: true }),
}, initialState);

export const upload = createAction(UPLOAD, files => files);
export const uploading = createAction(UPLOADING, file => file);
export const requestList = createAction(REQUEST_LIST, f => f);
export const list = createAction(LIST, list => list);
export const request = createAction(REQUEST, id => id);
export const file = createAction(FILE, file => file);
export const update = createAction(UPDATE, (id, file) => ({ id, file }));
export const removeFile = createAction(REMOVE_FILE, file => file);
export const error = createAction(ERROR, e => e);
export const success = createAction(SUCCESS, any => any);
export const lastUploadedFile = createAction(LAST_UPLOADED_FILE, payload => payload);
export const lastRemovedFile = createAction(LAST_REMOVED_FILE, file => file);
export const remove = createAction(REMOVE, id => id);
export const open = createAction(OPEN, id => id);



export const fileById = (state, id) => state.files.files.find(f => f.id === id);
export const filesList = state => state.files.files;
export const getLastUploadedFile = state => state.files.lastUploadedFile;