import React, { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DocumentIcon, FolderIcon } from '@heroicons/react/solid';
import { formatBytes } from '../functions';

const MAX_FILENAME_LENGTH_TO_SHOW = 30;

// @ts-ignore
function File({ file, onDoubleClick = () => {}, onClick = () => {}, folder = false, editing = false, onRename, selected = false, onMoveToFolder = (id:string, folderId: string, isFolder: boolean) => {} }) {
    const [isEditing, setIsEditing] = useState(editing);

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'FILE',
        end: (_, monitor) => {
            const result: { id: string } = monitor.getDropResult();
            if (result) {
                onMoveToFolder(file.id, result.id, folder);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }))

    const [{ isDragHover }, drop] = useDrop(() => ({
        accept: ['FILE'],
        drop: () => {
            return { id: file.id }
        },
        collect: (monitor) => ({
            isDragHover: monitor.isOver()
        })
    }));

    function fileName() {
        const dotIndex = file.name.lastIndexOf('.');
        const ext = dotIndex !== -1 ? file.name.substring(dotIndex + 1) : '';
        let dots = dotIndex > MAX_FILENAME_LENGTH_TO_SHOW ? '...' : '.';
        if (dotIndex === -1) {
            dots = file.name.length > MAX_FILENAME_LENGTH_TO_SHOW ? '...' : '';
        }
        return file.name.substring(0, dotIndex === -1 ? undefined : dotIndex).substring(0, MAX_FILENAME_LENGTH_TO_SHOW) + dots + ext;
    }
    function handleFocus() {
        setIsEditing(true);
    }
    function handleBlur(e) {
        const newName = e.currentTarget.textContent;
        setIsEditing(false);
        if (newName !== file.name) {
            onRename(newName);
        }
    }
    if (!file || !file.id) {
        return null;
    }
    return drag(
        <div
            ref={folder ? drop : null}
            className={`w-24 text-center group relative group-hover:z-20 cursor-default select-none rounded duration-200 border border-transparent border-dashed ${selected ? 'bg-mint bg-opacity-20' : ''} ${isDragging || isDragHover ? 'opacity-70' : ''} ${isDragHover ? 'border-mint' : ''}`}
            onDoubleClick={onDoubleClick}
        >
            <div onClick={onClick}>
                {folder
                    ? <FolderIcon className="h-16 w-16 text-mint mx-auto group-hover:opacity-90 duration-200" />
                    : <DocumentIcon className="h-12 w-12 text-mint mx-auto group-hover:opacity-90 duration-200"/>}
                {!folder && <div className="italic text-xxs text-gray-600">{formatBytes(file.size)}</div>}
                <div className={`text-xs break-all group-hover:opacity-0 ${isEditing ? 'opacity-0' : ''}`}>{fileName()}</div>
            </div>
            <div
                contentEditable={true}
                suppressContentEditableWarning={true}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`text-xs ${isEditing ? 'block' : 'hidden'} group-hover:block top-16 left-0 right-0 absolute break-all`}
                style={{ marginTop: folder ? 0 : -1 }}
            >
                {file.name}
            </div>
        </div>
    );
}

export default File;