import React from 'react';

function Logo({ size = 92 }) {
    // E55841
    return (
        <svg viewBox="0 0 92 92" width={size} height={size}>
            <path clipRule="evenodd" d="M46 91C70.8528 91 91 70.8528 91 46C91 21.1472 70.8528 1 46 1C21.1472 1 1 21.1472 1 46C1 70.8528 21.1472 91 46 91ZM46.3753 56.5001C51.9672 56.5001 56.5003 51.967 56.5003 46.3751C56.5003 40.7832 51.9672 36.2501 46.3753 36.2501C40.7834 36.2501 36.2503 40.7832 36.2503 46.3751C36.2503 51.967 40.7834 56.5001 46.3753 56.5001Z" fill="#454545" fillRule="evenodd"/>
            <path d="M90.5 46C90.5 70.5767 70.5767 90.5 46 90.5V91.5C71.129 91.5 91.5 71.129 91.5 46H90.5ZM46 1.5C70.5767 1.5 90.5 21.4233 90.5 46H91.5C91.5 20.871 71.129 0.5 46 0.5V1.5ZM1.5 46C1.5 21.4233 21.4233 1.5 46 1.5V0.5C20.871 0.5 0.5 20.871 0.5 46H1.5ZM46 90.5C21.4233 90.5 1.5 70.5767 1.5 46H0.5C0.5 71.129 20.871 91.5 46 91.5V90.5ZM56.0003 46.3751C56.0003 51.6908 51.691 56.0001 46.3753 56.0001V57.0001C52.2433 57.0001 57.0003 52.2431 57.0003 46.3751H56.0003ZM46.3753 36.7501C51.691 36.7501 56.0003 41.0593 56.0003 46.3751H57.0003C57.0003 40.507 52.2433 35.7501 46.3753 35.7501V36.7501ZM36.7503 46.3751C36.7503 41.0593 41.0596 36.7501 46.3753 36.7501V35.7501C40.5073 35.7501 35.7503 40.507 35.7503 46.3751H36.7503ZM46.3753 56.0001C41.0596 56.0001 36.7503 51.6908 36.7503 46.3751H35.7503C35.7503 52.2431 40.5073 57.0001 46.3753 57.0001V56.0001Z" fill="black"/>
            <path clipRule="evenodd" d="M46.375 76C62.7364 76 76 62.7364 76 46.375C76 30.0136 62.7364 16.75 46.375 16.75C30.0136 16.75 16.75 30.0136 16.75 46.375C16.75 62.7364 30.0136 76 46.375 76ZM46.3752 57.3321C52.4267 57.3321 57.3324 52.4264 57.3324 46.3749C57.3324 40.3235 52.4267 35.4177 46.3752 35.4177C40.3237 35.4177 35.418 40.3235 35.418 46.3749C35.418 52.4264 40.3237 57.3321 46.3752 57.3321Z" fill="black" fillRule="evenodd"/>
            <path d="M75.5 46.375C75.5 62.4603 62.4603 75.5 46.375 75.5V76.5C63.0126 76.5 76.5 63.0126 76.5 46.375H75.5ZM46.375 17.25C62.4603 17.25 75.5 30.2897 75.5 46.375H76.5C76.5 29.7374 63.0126 16.25 46.375 16.25V17.25ZM17.25 46.375C17.25 30.2897 30.2897 17.25 46.375 17.25V16.25C29.7374 16.25 16.25 29.7374 16.25 46.375H17.25ZM46.375 75.5C30.2897 75.5 17.25 62.4603 17.25 46.375H16.25C16.25 63.0126 29.7374 76.5 46.375 76.5V75.5ZM56.8324 46.3749C56.8324 52.1503 52.1505 56.8321 46.3752 56.8321V57.8321C52.7028 57.8321 57.8324 52.7026 57.8324 46.3749H56.8324ZM46.3752 35.9177C52.1505 35.9177 56.8324 40.5996 56.8324 46.3749H57.8324C57.8324 40.0473 52.7028 34.9177 46.3752 34.9177V35.9177ZM35.918 46.3749C35.918 40.5996 40.5998 35.9177 46.3752 35.9177V34.9177C40.0475 34.9177 34.918 40.0473 34.918 46.3749H35.918ZM46.3752 56.8321C40.5998 56.8321 35.918 52.1503 35.918 46.3749H34.918C34.918 52.7026 40.0475 57.8321 46.3752 57.8321V56.8321Z" fill="black"/>
            <path d="M12.4893 46.9573C12.4893 27.9211 27.9211 12.4893 46.9573 12.4893" stroke="black"/>
            <path d="M4.83008 46.9575C4.83008 23.691 23.6913 4.82983 46.9577 4.82983" stroke="black"/>
            <path d="M8.65967 46.9575C8.65967 25.8062 25.8062 8.65967 46.9575 8.65967" stroke="black"/>
            <path clipRule="evenodd" d="M46.375 66.25C57.3517 66.25 66.25 57.3517 66.25 46.375C66.25 35.3983 57.3517 26.5 46.375 26.5C35.3983 26.5 26.5 35.3983 26.5 46.375C26.5 57.3517 35.3983 66.25 46.375 66.25ZM46.3749 56.5001C51.9667 56.5001 56.4999 51.967 56.4999 46.3751C56.4999 40.7832 51.9667 36.2501 46.3749 36.2501C40.783 36.2501 36.2499 40.7832 36.2499 46.3751C36.2499 51.967 40.783 56.5001 46.3749 56.5001Z" fill="#fff" fillRule="evenodd"/>
            <path d="M65.75 46.375C65.75 57.0755 57.0755 65.75 46.375 65.75V66.75C57.6278 66.75 66.75 57.6278 66.75 46.375H65.75ZM46.375 27C57.0755 27 65.75 35.6745 65.75 46.375H66.75C66.75 35.1222 57.6278 26 46.375 26V27ZM27 46.375C27 35.6745 35.6745 27 46.375 27V26C35.1222 26 26 35.1222 26 46.375H27ZM46.375 65.75C35.6745 65.75 27 57.0755 27 46.375H26C26 57.6278 35.1222 66.75 46.375 66.75V65.75ZM55.9999 46.3751C55.9999 51.6908 51.6906 56.0001 46.3749 56.0001V57.0001C52.2429 57.0001 56.9999 52.2431 56.9999 46.3751H55.9999ZM46.3749 36.7501C51.6906 36.7501 55.9999 41.0593 55.9999 46.3751H56.9999C56.9999 40.507 52.2429 35.7501 46.3749 35.7501V36.7501ZM36.7499 46.3751C36.7499 41.0593 41.0591 36.7501 46.3749 36.7501V35.7501C40.5068 35.7501 35.7499 40.507 35.7499 46.3751H36.7499ZM46.3749 56.0001C41.0591 56.0001 36.7499 51.6908 36.7499 46.3751H35.7499C35.7499 52.2431 40.5068 57.0001 46.3749 57.0001V56.0001Z" fill="black"/>
        </svg>
    );
}
export default Logo;