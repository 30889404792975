import React from 'react';
import ReactDOM from 'react-dom';

import Application from './Application';
import { initialState } from './ducks'

const ROOT_APP_CLASSNAME = 'cloud'

const state = {
    ...initialState,
}

let appContainer = document.getElementById(ROOT_APP_CLASSNAME)
if (!appContainer) {
    appContainer = document.createElement('div');
    appContainer.id = ROOT_APP_CLASSNAME;
    document.body.appendChild(appContainer);
}
// @ts-ignore
ReactDOM.render(<Application state={state} />, appContainer);