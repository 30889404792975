import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import UploadForm from '../components/UploadForm';
import * as filesActions from '../ducks/files';
import * as foldersActions from '../ducks/folders';
import { RootState } from '../Application';
import Files from '../components/Files';
import UploadWrapper from '../components/UploadWrapper';
import Loader from '../components/Loader';
import ControlBar from '../components/ControlBar';
import { usePrevious } from '../hooks/usePrevious';

function FileManager({ uploadFiles, uploadingFile, files, folders, requestFiles,  requestFolders, openFile, createFolder, updateFolder, updateFile, loading, removeFile, removeFolder, history, match, requestFolder, currentFolder }): ReactElement {
    const [selected, setSelected] = useState(null);
    const prevFolder = usePrevious(match.params.folder);

    useEffect(() => {
        if (!match.params.folder && files.length === 0 && folders.length === 0) {
            requestFolders(match.params.folder);
            requestFiles(match.params.folder);
        }
    }, []);

    useEffect(() => {
        setSelected(null);
        if (prevFolder != match.params.folder) {
            requestFolder(match.params.folder);
            requestFolders(match.params.folder);
            requestFiles(match.params.folder);
        }
    }, [match.params.folder]);

    function handleCreateFolder() {
        const newFolderName = 'Новая папка';
        const newFoldersAmount = folders.filter(f => f.name.indexOf(newFolderName) === 0).length;
        createFolder({
            name: newFolderName + (newFoldersAmount ? (newFoldersAmount + '') : '')
        });
    }

    function handleRenameFile (id, name) {
        updateFile(id, { name });
    }

    function handleRenameFolder (id, name) {
        updateFolder(id, { name });
    }

    function handleSelect(id) {
        setSelected(id);
    }

    function handleDelete() {
        if (selected) {
            const file = files.find(f => f.id === selected);
            if (file) {
                removeFile(file.id)
                return;
            }
            const folder = folders.find(f => f.id === selected);
            if (folder) {
                removeFolder(folder.id)
            }
        }
        setSelected(null);
    }

    function handleMoveToFolder(id, folderId, isFolder) {
        isFolder
            ? updateFolder(id, { folderId: folderId })
            : updateFile(id, { folderId: folderId })
    }

    function handleMoveToParent() {
        if (selected) {
            const file = files.find(f => f.id === selected);
            if (file) {
                updateFile(file.id, { folderId: currentFolder.folderId || null })
                return;
            }
            const folder = folders.find(f => f.id === selected);
            if (folder) {
                updateFolder(folder.id, { folderId: currentFolder.folderId || null })
            }
        }
        setSelected(null);
    }

    const handleOpenFolder = useCallback((id) => history.push('/disco/' + id), [history]);
    const handleOpenParentFolder = useCallback(() => history.push('/disco'), [history]);

    return (
        <div className="h-screen overflow-y-auto">
            {/*<UploadForm onFilesUpload={uploadFiles} />*/}
            <UploadWrapper onFilesUpload={uploadFiles}>
                <ControlBar
                    onClickBack={handleOpenParentFolder}
                    onCreateFolder={handleCreateFolder}
                    selected={selected}
                    onClickDelete={handleDelete}
                    onMoveToTop={handleMoveToParent}
                    current={match.params.folder}
                />
                <Files
                    files={files}
                    folders={folders}
                    onOpenFile={openFile}
                    selected={selected}
                    onOpenFolder={handleOpenFolder}
                    onRenameFile={handleRenameFile}
                    onRenameFolder={handleRenameFolder}
                    onSelect={handleSelect}
                    onMoveToFolder={handleMoveToFolder}
                />
            </UploadWrapper>
            {uploadingFile && <Loader text={`${uploadingFile?.name} is uploading...`} /> }
            <Loader show={!uploadingFile && loading} />
        </div>
    );
}
export default connect(
    (state:RootState) => ({
        uploadingFile: state.files.uploadingFile,
        files: state.files.files,
        folders: state.folders.list,
        lastCreatedFolderId: state.folders.lastCreatedFolderId,
        loading: state.folders.loading || state.files.loading,
        currentFolder: state.folders.current,
    }),
    dispatch => ({
        uploadFiles: files => dispatch(filesActions.upload(files)),
        requestFiles: (f) => dispatch(filesActions.requestList(f)),
        openFile: id => dispatch(filesActions.open(id)),
        requestFolder: id => dispatch(foldersActions.request(id)),
        requestFolders: (f) => dispatch(foldersActions.requestList(f)),
        createFolder: f => dispatch(foldersActions.create(f)),
        updateFolder: (id, f) => dispatch(foldersActions.update(id, f)),
        updateFile: (id, f) => dispatch(filesActions.update(id, f)),
        removeFolder: id => dispatch(foldersActions.remove(id)),
        removeFile: id => dispatch(filesActions.remove(id)),
    })
)(FileManager);