import React, { ReactElement } from 'react';

interface ButtonProps {
    className?: string;
    disabled?: boolean;
    onClick?: (any: any) => any;
    type?: 'button'|'submit'|'reset';
    children?: any;
}

function Button(props: ButtonProps): ReactElement {
    const className = [
        props.className,
        'rounded bg-mint px-5 py-3 text-white font-bold hover:opacity-95 duration-300'
    ].filter(c => c).join(' ')
    return (
        <button
            type={props.type || 'submit'}
            className={className}
            onClick={props.onClick || (() => {})}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    );
}

export default Button;